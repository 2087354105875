import { Box } from "@mui/material";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import {
  AddDraftQuotationInput,
  AttachmentInput,
  CompEventQuotation,
  CompEventQuotationPrompt,
  DraftQuotation,
  EditDraftQuotationInput,
} from "generated/graphql";
import {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { TableHeader } from "../../../../ActionModal/TableHeader";
import { QuotationsTable } from "./QuotationsTable/QuotationsTable";
import { RecordCEQuotation } from "./RecordCEQuotation";
import { SendCEQuotation } from "./SendCEQuotation";
import { useSubmitQuotationCEAction } from "./useSubmitQuotationCEAction";
import { CEActionFormProps } from "../CompEventAction/CompEventActionView";
import { useCEQuotationAssessmentModal } from "../../../CEQuotationAssessmentModal/useCEQuotationAssessmentModal";
import { QuotationAssessmentFormDataType } from "../../../CEQuotationAssessmentModal/CEQuotationAssessment/CEQuotationAssessment.decl";
import {
  CEQuotationAssessmentModal,
  CEQuotationAssessmentModalType,
} from "../../../CEQuotationAssessmentModal/CEQuotationAssessmentModal";

const defaultFormData: CompEventQuotationPrompt = {
  draftQuotations: [],
};

export const SubmitQuotationCEAction: React.FC<
  CEActionFormProps & { triggersAuthWorkflow: boolean }
> = ({ apiRef, triggersAuthWorkflow, onClose }) => {
  const { compEvent, contract } = useContext(CompEventWidgetContext);

  const [formData, setFormData] =
    useState<CompEventQuotationPrompt>(defaultFormData);
  const [sendModalVisibility, setSendModalVisibility] = useState(false);
  const [recordModalVisibility, setRecordModalVisibility] = useState(false);
  const [selectedQuotationId, setSelectedQuotationId] = useState<string>();

  const updatedAttachmentsRef = useRef<AttachmentInput[]>();

  const {
    modalVisibility: quotationModalVisibility,
    toggleModalVisibility: toggleQuotationModalVisibility,
    loading: quotationsLoading,
    addDraftQuotation,
    editDraftQuotation,
    removeDraftQuotation,
  } = useCEQuotationAssessmentModal();

  const quotationIds = useMemo(
    () => formData.draftQuotations.map((quotation) => quotation.id),
    [formData]
  );

  const {
    sendCEQuotation,
    recordCEQuotation,
    draftQuotations: promptDraftQuotations,
    loading: submitQuotationsLoading,
    actionLoading,
  } = useSubmitQuotationCEAction(triggersAuthWorkflow);

  const toggleSendModalVisibility = () => {
    setSendModalVisibility((state) => !state);
  };

  const toggleRecordModalVisibility = () => {
    setRecordModalVisibility((state) => !state);
  };

  const handleSendCEQuotation = async () => {
    await sendCEQuotation({
      variables: {
        input: {
          compEventId: compEvent?.id!,
          quotationIds,
          preferredQuotationId: quotationIds[0],
        },
      },
    });
    toggleSendModalVisibility();
    onClose();
  };

  const handleRecordCEQuotation = async (
    extraData: RecordProductItemExtraData
  ) => {
    await recordCEQuotation({
      variables: {
        input: {
          compEventId: compEvent?.id!,
          quotationIds,
          preferredQuotationId: quotationIds[0],
          dateSent: extraData.dateSent,
          givenById: extraData.givenById,
          number: extraData.number,
        },
      },
    });

    toggleRecordModalVisibility();
    onClose();
  };

  const isFormValid = useMemo(
    () => !!formData.draftQuotations.length,
    [formData]
  );

  const handleCreateEditQuotation = useCallback(
    async (
      quotationAssessment: QuotationAssessmentFormDataType,
      keepModalOpen?: boolean
    ) => {
      const quotationData = quotationAssessment as
        | AddDraftQuotationInput
        | EditDraftQuotationInput;

      if (formData.draftQuotations.length) {
        // edit mode
        const { data } = await editDraftQuotation({
          variables: { input: quotationData as EditDraftQuotationInput },
        });

        if (data) {
          setFormData((crtFormData) => ({
            ...crtFormData,
            draftQuotations: [data.editDraftQuotation as DraftQuotation],
          }));
        }
      } else {
        // create new draft quotation
        const { data } = await addDraftQuotation({
          variables: { input: quotationData },
        });

        if (data) {
          setFormData((crtFormData) => ({
            ...crtFormData,
            draftQuotations: [data.addDraftQuotation as DraftQuotation],
          }));
        }
      }

      if (!keepModalOpen) {
        toggleQuotationModalVisibility();
      }
    },
    [
      formData,
      addDraftQuotation,
      editDraftQuotation,
      toggleQuotationModalVisibility,
    ]
  );

  const handleAttachmentsChange = useCallback(
    (draftQuotationAssessmentData: QuotationAssessmentFormDataType) => {
      updatedAttachmentsRef.current = draftQuotationAssessmentData.attachments;
      const isEditMode = !!selectedQuotationId;

      if (isEditMode) {
        // update live the attachments if form is valid
        handleCreateEditQuotation(draftQuotationAssessmentData, true);
      }
    },
    [handleCreateEditQuotation, selectedQuotationId]
  );

  const handleQuotationAssessmentModalClose = () => {
    toggleQuotationModalVisibility();
    setSelectedQuotationId(undefined);
  };

  const handleQuotationRowClick = (
    quotation: DraftQuotation | CompEventQuotation
  ) => {
    setSelectedQuotationId((quotation as DraftQuotation).id);
    toggleQuotationModalVisibility();
  };

  const handleDeleteDraftQuotation = useCallback(
    (quotationId: string) => {
      removeDraftQuotation({ variables: { id: quotationId } });
    },
    [removeDraftQuotation]
  );

  const handleNewQuotationClick = () => {
    setSelectedQuotationId(undefined);
    toggleQuotationModalVisibility();
  };

  const selectedDraftQuotation = useMemo(
    () =>
      formData.draftQuotations.find(
        (quotation) => quotation.id === selectedQuotationId
      ),
    [formData, selectedQuotationId]
  );

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => isFormValid,
      record: toggleRecordModalVisibility,
      send: toggleSendModalVisibility,
    }),
    [isFormValid]
  );

  useEffect(() => {
    if (promptDraftQuotations) {
      setFormData({
        draftQuotations: promptDraftQuotations,
      });
    }
  }, [promptDraftQuotations]);

  return !selectedQuotationId &&
    (quotationsLoading || submitQuotationsLoading) ? (
    <CenteredLoadingIndicator />
  ) : (
    <>
      {quotationModalVisibility && (
        <CEQuotationAssessmentModal
          open={quotationModalVisibility}
          type={CEQuotationAssessmentModalType.Quotation}
          draftQuotation={selectedDraftQuotation}
          contractCurrency={contract.valueCurrency ?? ""}
          contractKeyDates={contract.keyDates}
          contractSections={contract.sections}
          onAttachmentsChange={handleAttachmentsChange}
          onPrimaryClick={handleCreateEditQuotation}
          onClose={handleQuotationAssessmentModalClose}
          onSecondaryClick={handleQuotationAssessmentModalClose}
        />
      )}
      {isFormValid && sendModalVisibility && (
        <SendCEQuotation
          open={sendModalVisibility}
          draftQuotations={formData.draftQuotations}
          onPrimaryClick={handleSendCEQuotation}
          onSecondaryClick={toggleSendModalVisibility}
          onClose={toggleSendModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      {isFormValid && recordModalVisibility && (
        <RecordCEQuotation
          open={recordModalVisibility}
          draftQuotations={formData.draftQuotations}
          onPrimaryClick={handleRecordCEQuotation}
          onSecondaryClick={toggleRecordModalVisibility}
          onClose={toggleRecordModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      <Box display="flex" flexDirection="column">
        <TableHeader
          type="Quotation"
          onAdd={handleNewQuotationClick}
          disabled={!!formData.draftQuotations.length}
        />
        <QuotationsTable
          contractCurrency={contract.valueCurrency ?? ""}
          loading={quotationsLoading}
          quotations={formData.draftQuotations}
          onRowClick={handleQuotationRowClick}
          onDelete={handleDeleteDraftQuotation}
        />
      </Box>
    </>
  );
};
