import { Box, css, Stack, styled } from "@mui/material";
import { Header } from "./components/Header";
import { SectionContainer } from "components/miscellaneous/SectionContainer";
import { useRef, useState } from "react";
import {
  DailyDiarySearchRecordsInput,
  DailyDiarySearchRecordsMetadataQuery,
} from "generated/graphql";
import { SearchForm } from "./components/SearchForm";
import { SearchResults } from "./components/SearchResults";
import { useSearchParams } from "react-router-dom";
import { useDailyDiariesSearchMetadata } from "./hooks/useDailyDiariesSearchMetadata";
import { ResultsSectionPublicApi } from "./DailyDiariesSearch.decl";
import { useIntercom } from "react-use-intercom";
import { IntercomEvents } from "constants/intercom";
import { getMetadataSet3 } from "containers/Projects/utils/intercom";
import { useProductInstanceDetailsLite } from "./hooks/useProductInstanceDetailsLite";
import { useGlobalContext } from "state-management/globalContext/Global.context";

const DiarySearchSectionContainer = styled(SectionContainer, {
  shouldForwardProp: (prop) => prop !== "collapsed",
})<{
  collapsed?: boolean;
}>(
  ({ theme, collapsed }) => css`
    padding: ${theme.spacing(collapsed ? 0 : 2)} 0;
    box-sizing: border-box;
    border-radius: ${collapsed ? "8px" : "16px"};
    overflow: hidden;

    transition: width 0.15s ease-out, height 0.15s ease-out;
  `
);

export const DailyDiariesSearch: React.FC = () => {
  const { trackEvent } = useIntercom();
  const [searchParams] = useSearchParams();
  const productInstanceId = searchParams.get("productInstanceId");
  const {
    globalConfiguration: { dailyDiarySearchPageSize },
  } = useGlobalContext();
  const contractId = searchParams.get("contractId");
  const resultsRef = useRef<ResultsSectionPublicApi>(null);
  const [searchResultsMetadata, setSearchResultsMetadata] =
    useState<DailyDiarySearchRecordsMetadataQuery>();
  const [selectedTabId, setSelectedTabId] = useState<string>("");
  const [canExport, setCanExport] = useState<boolean>();

  const [searchResultsMetadataLoading, setSearchResultsMetadataLoading] =
    useState<boolean>(false);
  const [searchCollapsed, setSearchCollapsed] = useState<boolean>(false);
  const [searchFormInput, setSearchFormInput] =
    useState<DailyDiarySearchRecordsInput>();

  const { searchRecordsMetadata } = useDailyDiariesSearchMetadata();

  const { productInstanceData } = useProductInstanceDetailsLite(
    productInstanceId!
  );

  const handleSearch = async (input: DailyDiarySearchRecordsInput) => {
    setSearchResultsMetadataLoading(true);

    setSearchFormInput(input);
    if (productInstanceData) {
      trackEvent(IntercomEvents.SearchedDailyDiaries, {
        ...getMetadataSet3({
          productInstanceName: productInstanceData.productInstance.description,
          projectFn:
            productInstanceData.productInstance.contract.project.friendlyName,
          contractFn: productInstanceData.productInstance.contract.friendlyName,
          version: process.env.REACT_APP_VERSION!,
        }),
        KeywordLength: input.keywords?.length ?? 0,
      });
    }

    const { data } = await searchRecordsMetadata({ input });

    setSearchResultsMetadata(data);
    setSearchResultsMetadataLoading(false);
  };

  const handleExport = () => {
    resultsRef.current?.exportRecords();
  };

  const handleToggleCollapseSearchForm = () => {
    setSearchCollapsed((crt) => !crt);
  };

  return productInstanceId && contractId ? (
    <Box height="100%" width="100%" display="flex" flexDirection="column">
      <Header
        onExport={handleExport}
        canExport={canExport}
        onExportAll={() => {}}
      />
      <Box sx={{ flex: 1, width: "100%", overflow: "hidden" }} mt={3}>
        <Stack
          direction="row"
          spacing={2}
          px={6}
          pb={3}
          height="100%"
          boxSizing="border-box"
        >
          <DiarySearchSectionContainer flex={1}>
            <SearchResults
              productInstanceId={productInstanceId}
              searchResultsMetadata={searchResultsMetadata}
              searchResultsMetadataLoading={searchResultsMetadataLoading}
              searchFormInput={searchFormInput}
              selectedTabId={selectedTabId}
              apiRef={resultsRef}
              onTabSelectionChange={setSelectedTabId}
              onCanExportChange={setCanExport}
            />
          </DiarySearchSectionContainer>
          <DiarySearchSectionContainer
            width={searchCollapsed ? "36px" : "376px"}
            height={searchCollapsed ? "36px" : "fit-content"}
            // height={searchCollapsed ? "36px" : "530px"}
            collapsed={searchCollapsed}
          >
            <SearchForm
              collapsed={searchCollapsed}
              productInstanceId={productInstanceId}
              pageSize={dailyDiarySearchPageSize}
              onSearch={handleSearch}
              onToggleCollapse={handleToggleCollapseSearchForm}
            />
          </DiarySearchSectionContainer>
        </Stack>
      </Box>
    </Box>
  ) : null;
};
