import { Box, Stack } from "@mui/material";
import { Attachment } from "generated/graphql";
import { useEffect, useState } from "react";
import { AttachmentsDencity, FileType } from "../Attachments.decl";
import { AttachmentsContainer } from "../Attachments.styled";
import { filterAttachmentsByTab } from "../Attachments.utils";
import { AttachmentTab } from "./AttachmentsTabs";
import { AttachmentsTitle } from "./AttachmentsTitle";
import { FileListItem } from "./FileListItem/FileListItem";
import {
  AttachmentsLayout,
  AttachmentsHeader,
} from "./AttachmentsHeader/AttachmentsHeader";
import { FileGridItem } from "./FileGridItem/FileGridItem";

export type AttachmentsReadOnlyProps = {
  attachments: (Attachment | FileType)[];
  showTabs?: boolean;
  dencity?: AttachmentsDencity;
  hideTitle?: boolean;
  hideHeader?: boolean;
  onAttachmentClick: (attachment: FileType | Attachment) => void;
};

export const AttachmentsReadOnly: React.FC<AttachmentsReadOnlyProps> = ({
  attachments,
  showTabs,
  dencity,
  hideTitle,
  hideHeader,
  onAttachmentClick,
}) => {
  const [selectedTab, setSelectedTab] = useState(AttachmentTab.All);
  const [tabAttachments, setTabAttachments] = useState<
    (Attachment | FileType)[]
  >(filterAttachmentsByTab(AttachmentTab.All, attachments));
  const [attachmentsLayout, setAttachmentsLayout] = useState<AttachmentsLayout>(
    AttachmentsLayout.List
  );

  const handleSelectedTabChange = (selectedTab: string) => {
    setSelectedTab(selectedTab as AttachmentTab);
  };

  useEffect(() => {
    setTabAttachments(
      filterAttachmentsByTab(selectedTab as AttachmentTab, attachments)
    );
  }, [selectedTab, attachments]);

  return (
    <AttachmentsContainer>
      {!hideTitle && (
        <AttachmentsTitle
          attachmentsCount={attachments.length}
          dencity={dencity}
          noMarginBottom={!attachments.length}
        />
      )}
      {attachments.length > 0 && !hideHeader && (
        <AttachmentsHeader
          mt={0}
          showTabs={!!showTabs}
          attachments={attachments}
          attachmentsLayout={attachmentsLayout}
          onFilterTabChange={handleSelectedTabChange}
          onLayoutChange={setAttachmentsLayout}
        />
      )}
      {attachmentsLayout === AttachmentsLayout.List ? (
        <Stack
          spacing={attachmentsLayout === AttachmentsLayout.List ? 1 : 1.5}
          mt={attachments.length ? 2 : 0}
        >
          {tabAttachments?.map((file) => (
            <FileListItem
              key={file.id}
              file={file}
              editable={false}
              onClick={() => onAttachmentClick(file)}
            />
          ))}
        </Stack>
      ) : (
        <Box
          display="grid"
          gridTemplateColumns={
            dencity === AttachmentsDencity.Comfortable
              ? "repeat(auto-fit, 270px)"
              : "repeat(auto-fit, 260px)"
          }
          gap={1.5}
          mt={2}
        >
          {tabAttachments?.map((file) => (
            <FileGridItem
              key={file.id}
              file={file}
              editable={false}
              onClick={() => onAttachmentClick(file)}
            />
          ))}
        </Box>
      )}
    </AttachmentsContainer>
  );
};
