import { AuthorizationToastTransMessage } from "components/Authorization/AuthorizationToastTransMessage";
import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import { OnMutationDone } from "types/decl";
import {
  CompEventQuotation,
  CompEventQuotationAcceptancePromptQuery,
  CompEventQuotationAcceptancePromptQueryVariables,
  RecordCompEventQuotationAcceptanceMutation,
  RecordCompEventQuotationAcceptanceMutationVariables,
  SendCompEventQuotationAcceptanceMutation,
  SendCompEventQuotationAcceptanceMutationVariables,
} from "generated/graphql";
import { recordCompEventQuotationAcceptanceMutation } from "graphql/mutations/recordCompEventQuotationAcceptance";
import { sendCompEventQuotationAcceptanceMutation } from "graphql/mutations/sendCompEventQuotationAcceptance";
import { compEventQuotationAcceptancePromptQuery } from "graphql/queries/compEventQuotationAcceptancePrompt.query";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useAcceptQuotationCEAction = (
  skip?: boolean,
  triggersAuthWorkflow?: boolean
) => {
  const { t } = useTranslation();
  const { compEvent, onChange } = useContext(CompEventWidgetContext);

  const {
    data: compEventQuotationAcceptancePrompt,
    loading: compEventQuotationAcceptancePromptLoading,
  } = useGraphQuery<
    CompEventQuotationAcceptancePromptQuery,
    CompEventQuotationAcceptancePromptQueryVariables
  >(compEventQuotationAcceptancePromptQuery, {
    variables: {
      input: {
        compEventId: compEvent?.id!,
      },
    },
    fetchPolicy: "no-cache",
    skip,
  });

  const onUpdated: OnMutationDone = useCallback(
    (cache) => {
      cache.evict({ id: "ROOT_QUERY", fieldName: "compEventItems" });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "compEventHistoryItems",
        args: { compEventId: compEvent!.id },
      });
      cache.gc();
      onChange?.();
    },
    [onChange, compEvent]
  );

  const [
    sendCEQuotationAcceptance,
    { loading: sendCEQuotationAcceptanceLoading },
  ] = useGraphMutation<
    SendCompEventQuotationAcceptanceMutation,
    SendCompEventQuotationAcceptanceMutationVariables
  >(
    sendCompEventQuotationAcceptanceMutation,
    {
      update: onUpdated,
    },
    triggersAuthWorkflow ? (
      <AuthorizationToastTransMessage />
    ) : (
      t("common.successMessages.entitySent", {
        entity: t("Projects.CompEvents.compEventQuotationAcceptance"),
      })
    ),
    {
      persist: triggersAuthWorkflow,
    }
  );

  const [
    recordCEQuotationAcceptance,
    { loading: recordCEQuotationAcceptanceLoading },
  ] = useGraphMutation<
    RecordCompEventQuotationAcceptanceMutation,
    RecordCompEventQuotationAcceptanceMutationVariables
  >(
    recordCompEventQuotationAcceptanceMutation,
    {
      update: onUpdated,
    },
    triggersAuthWorkflow ? (
      <AuthorizationToastTransMessage />
    ) : (
      t("common.successMessages.entityRecorded", {
        entity: t("Projects.CompEvents.compEventQuotationAcceptance"),
      })
    ),
    {
      persist: triggersAuthWorkflow,
    }
  );

  // const quotations = useMemo(() => [], []);
  const quotations = useMemo(
    () =>
      (compEventQuotationAcceptancePrompt?.compEventQuotationAcceptancePrompt
        .quotations as CompEventQuotation[]) ?? [],
    [compEventQuotationAcceptancePrompt]
  );

  return {
    quotations,
    // loading: false,
    loading: compEventQuotationAcceptancePromptLoading,
    actionLoading:
      sendCEQuotationAcceptanceLoading || recordCEQuotationAcceptanceLoading,
    sendCEQuotationAcceptance,
    recordCEQuotationAcceptance,
    sendCEQuotationAcceptanceLoading,
    recordCEQuotationAcceptanceLoading,
  };
};
