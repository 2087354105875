import { Box, css, styled } from "@mui/material";
import { mainNavigationHeight } from "components/MainNavigation/MainNavigation.styled";
import { customShadows } from "theme/extendedTheme";

export const ExplorerContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "hideBottomShadow",
})<{ hideBottomShadow?: boolean }>(
  ({ theme, hideBottomShadow = false }) => css`
    ${!hideBottomShadow &&
    css`
      box-shadow: ${customShadows.activeSmall};
    `}
    padding: ${theme.spacing(3)} ${theme.spacing(6)} ${theme.spacing(2)};
    z-index: 1;
    position: sticky;
    top: ${mainNavigationHeight}px;
    background: white;
    z-index: 10;
  `
);
