import { createTheme, ThemeOptions } from "@mui/material";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { ExtendedOptionStyles } from "components/StatusTag/StatusTagNew";
import { ItemStatusOptionStyles } from "generated/graphql";

const newFont = "'Open Sans', sans-serif";

export const customShadows = {
  activeSmall: "0px 1px 3px rgba(37, 44, 50, 0.12)",
  activeMedium:
    "0px 4px 6px rgba(37, 44, 50, 0.8), 0px 4px 8px rgba(37, 44, 50, 0.10)",
  popoverMedium:
    "0px 2px 6px rgba(37, 44, 50, 0.12), 0px 10px 20px rgba(37, 44, 50, 0.10)",
  modalLarge:
    "0px 8px 8px rgba(37, 44, 50, 0.15), 0px 15px 25px rgba(37, 44, 50, 0.15)",
  pagination: "box-shadow: 0px -1px 3px rgba(37, 44, 50, 0.8);",
  cards:
    "0px 1px 1px rgba(45, 64, 83, 0.05), 0px 1px 2px rgba(45, 64, 83, 0.02)",
  scroll:
    "0px 8px 6px rgba(37, 44, 50, 0.4), 0px 2px 4px rgba(37, 44, 50, 0.4)",
};

export const defaultPageBackgroundColor = "#f6f7f8";

// export const primaryShades = {
//   900: "#002D21",
//   800: "#0C3E31",
//   700: "#0A5E42",
//   600: "#00694D",
//   500: "#008558",
//   400: "#0D9C6C",
//   300: "#9DD1BF",
//   200: "#CFE9E0",
//   100: "#E9F3F0",
// };

// export const secondaryShades = {
//   900: "#042F11",
//   800: "#0D4A20",
//   700: "#0E8131",
//   600: "#00A332",
//   500: "#00BD3A",
//   400: "#43CD6D",
//   300: "#88DDA2",
//   200: "#DCF3E3",
//   100: "#E3F3E5",
// };

export const unofficialThemeColors = {
  red: "#CF3030",
  red1: "#EC212E",
  amber: "#EAAD0D",
  amber1: "#FCF0D1",
  amberDarker: "#CC6507",
  amberWidgetNextStep: "#FEF8E3",
  blueWidgetLastStep: "#F0F5FD",
  bluePale: "#f2f6fd",
  bluePaleDarker: "#e8edf8", // note: added by Adrian
  blueDarker: "#2DC6F6",
  blueEvenDarker: "#196689",
  blueVO: "#0E01A5",
  blueIntense: "#0969F9",
  greenPale: "#D4EDD8",
  greenDarker: "#0B8408",
  orange: "#EF9F05",
  purple: "#9D10DF",
  teal: "#008080",
  tealLighter: "#0091931A",
};

const extendedThemeOverrides: ThemeOptions = {
  palette: {
    primary: {
      900: "#002D21",
      800: "#0C3E31",
      700: "#0A5E42",
      600: "#00694D",
      500: "#008558",
      400: "#0D9C6C",
      300: "#9DD1BF",
      200: "#CFE9E0",
      100: "#E9F3F0",
    },
    secondary: {
      900: "#042F11",
      800: "#0D4A20",
      700: "#0E8131",
      600: "#00A332",
      500: "#00BD3A",
      400: "#43CD6D",
      300: "#88DDA2",
      200: "#DCF3E3",
      100: "#E3F3E5",
      main: "#00BD3A",
    },
    text: {
      primary: "#202020", // TODO: double-check
      disabled: "#C3CBD3",
    },
    grey: {
      900: "#252C32",
      800: "#304050",
      700: "#495765",
      600: "#62707F",
      500: "#7C8A98",
      400: "#C3CBD3",
      300: "#D7DBDF",
      200: "#EAEDF0",
      100: "#F2F4F6",
      A400: "#252C32",
    },
    background: {
      default: "#F6F7F9",
      paper: "#F3F4F7",
    },
    error: {
      light: "#FED8D9",
      main: "#E92B33",
    },
    warning: {
      light: "#FAF6CF",
      main: "#F1BD03",
    },
    info: {
      light: "#D4E4FA",
      main: "#186ADE",
    },
    success: {
      light: "#D4EDD8",
      main: "#2AA23E",
    },
    action: {
      hover: "#F2F4F6", // grey[100]
      selected: "#EAEDF0", // grey[200]
    },
  },
  typography: {
    fontFamily: newFont,
    h1: {
      fontSize: "32px",
      lineHeight: "40px",
      fontWeight: 600,
    },
    h2: {
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 600,
    },
    h3: {
      fontSize: "20px",
      lineHeight: "28px",
      fontWeight: 600,
    },
    h4: {
      fontSize: "18px",
      lineHeight: "28px",
      fontWeight: 400,
    },
    p1: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 400,
    },
    p2: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 400,
    },
    p3: {
      fontSize: "12px",
      lineHeight: "16px",
      fontWeight: 400,
    },
    caption1: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 400,
      letterSpacing: 0.8,
    },
    caption2: {
      fontSize: "12px",
      lineHeight: "24px",
      fontWeight: 700,
    },
    caption3: {
      fontSize: "10px",
      lineHeight: "14px",
      letterSpacing: 0.4,
      fontWeight: 700,
    },
    button: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 700,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: "8px",
          fontSize: "14px",
          textTransform: "none",
          "&.MuiButton-contained:not(.MuiButton-outlinedError):not(.MuiButton-containedError):hover":
            {
              backgroundColor: "#00694D",
            },
          "&.MuiButton-contained:not(.MuiButton-outlinedError):not(.MuiButton-containedError):active":
            {
              backgroundColor: "#0A5E42",
            },
          "&.MuiButton-contained.Mui-disabled": {
            backgroundColor: "#F2F4F6",
            color: "#7C8A98",
          },
          "&.MuiButton-outlined:not(.MuiButton-outlinedError):not(.MuiButton-containedError)":
            {
              borderColor: "#D7DBDF",
              color: "#304050",
            },
          "&.MuiButton-outlined:not(.MuiButton-outlinedError):not(.MuiButton-containedError):hover":
            {
              borderColor: "#D7DBDF",
              backgroundColor: "inherit",
              color: "#252C32",
            },
          "&.MuiButton-outlined:not(.MuiButton-outlinedError):not(.MuiButton-containedError):active":
            {
              borderColor: "#C3CBD3",
              color: "#304050",
            },
          "&.MuiButton-outlined:not(.MuiButton-outlinedError):not(.MuiButton-containedError).Mui-disabled":
            {
              borderColor: "#EAEDF0",
              color: "#C3CBD3",
            },
          "&.MuiButton-text": {
            color: "#304050",
          },
          "&.MuiButton-text:hover": {
            color: "#252C32",
            backgroundColor: "inherit",
          },
          "&.MuiButton-text:active": {
            color: "#304050",
          },
          "&.MuiButton-text.Mui-disabled": {
            color: "#C3CBD3",
          },
          "&.MuiButton-sizeLarge": {
            height: "44px",
          },
          "&.MuiButton-sizeMedium": {
            height: "36px",
          },
          "&.MuiButton-sizeSmall": {
            height: "32px",
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontWeight: 400,
          color: "#7C8A98",
          "&.Mui-selected": {
            fontWeight: 700,
          },
          "&:hover": {
            color: "#008558", // primary[500]
            boxShadow: "inset 0 -2px #008558",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          ".MuiTabs-indicator": {
            height: "4px",
          },
          ".MuiTab-root": {
            padding: 0,
            minWidth: "10px",
            marginLeft: "20px",
            marginRight: "20px",
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          "& .MuiList-root": {
            backgroundColor: "white",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "white",
          borderRadius: "12px",
          boxShadow: customShadows.popoverMedium,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "& fieldset.MuiOutlinedInput-notchedOutline": {
            borderColor: "#EAEDF0",
            borderRadius: "8px",
            color: "#304050",
          },
          "&:hover fieldset.MuiOutlinedInput-notchedOutline": {
            borderColor: "#7C8A98",
          },
          "&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline": {
            borderColor: "#008558",
            borderWidth: "1px",
          },
          "&.Mui-disabled fieldset.MuiOutlinedInput-notchedOutline": {
            backgroundColor: "#F2F4F6",
            color: "#C3CBD3",
            border: "none",
          },
          "& input::placeholder": {
            color: "#7C8A98",
          },
          "&.Mui-focused": {
            "& input::placeholder": {
              color: "#252C32",
            },
          },
          "&.Mui-disabled": {
            "& input": {
              zIndex: 2,
            },
            "& input::placeholder": {
              color: "#C3CBD3",
            },
          },
          "&.MuiInputBase-sizeSmall": {
            height: "44px",
            fontSize: "14px",
          },
          "&.MuiInputBase-sizeMedium": {
            height: "56px",
            fontSize: "16px",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          height: "40px",
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: ({ ownerState: { max } }) => ({
          ...[...Array(max)].reduce(
            (result, _, index) => ({
              ...result,
              [`& > .MuiAvatar-root:nth-of-type(${index + 1})`]: {
                zIndex: (max || 0) - index,
              },
            }),
            {}
          ),
        }),
      },
    },
  },
};

export const StatusStyles: Record<
  ExtendedOptionStyles | ItemStatusOptionStyles,
  { color: string; bgColor: string }
> = {
  Green: {
    color: "#2AA23E",
    bgColor: (extendedThemeOverrides.palette!.success! as any).light,
  },
  Amber: {
    color: unofficialThemeColors.amberDarker,
    bgColor: unofficialThemeColors.amber1,
  },
  Purple: {
    color: "#8718DE",
    bgColor: "#EFE0FA",
  },
  Blue: {
    color: "#186ADE",
    bgColor: "#E6EFFA",
  },
  Grey: {
    color: "#495765",
    bgColor: "#EAEDF0",
  },
  Black: {
    // TODO: Black has never been used. Update the colors once we have a use case and designs
    color: "#495765",
    bgColor: "#EAEDF0",
  },
  Red: {
    color: unofficialThemeColors.red,
    bgColor: "#FAEAEA",
  },
  Teal: {
    color: unofficialThemeColors.teal,
    bgColor: unofficialThemeColors.tealLighter,
  },
};

export const extendedTheme = createTheme(extendedThemeOverrides);
