import { Box } from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { CompEventItem, CompEventStatus, ProductType } from "generated/graphql";
import { useMemo, useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TabWithCount } from "../../TabsWithCount/TabsWithCount";
import { CompEventsTable } from "./components/CompEventsTable/CompEventsTable";
import { StyledProductItemsHeader } from "../../ProductItemsView/ProductItemsView.styled";
import { ProductItemsTablePublicAPI } from "../../ProductItemsView/ProductItemsView.decl";
import { CommonTabs } from "constants/constants";
import { useUsersAccess } from "../../ProductItemsView/hooks/useUsersAccess";
import { useSearchParams } from "react-router-dom";

export type CompEventsViewProps = {
  compEvents: CompEventItem[];
  loading: boolean;
};

export const CompEventsView: React.FC<CompEventsViewProps> = ({
  compEvents,
  loading,
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const productInstanceId = searchParams.get("productInstanceId");
  const productItemsTableRef = useRef<ProductItemsTablePublicAPI>(null);
  const [productItemsTableSelectionModel, setProductItemsTableSelectionModel] =
    useState<GridRowSelectionModel>();
  const [localCompEvents, setLocalCompEvents] = useState<CompEventItem[]>(
    compEvents || []
  );
  const [selectedTabId, setSelectedTabId] = useState<string | null>(
    CommonTabs.All
  );
  const { usersRoles, usersAccessLoading } = useUsersAccess(productInstanceId!);

  const tabs = useMemo(() => {
    const localTabs: TabWithCount[] = compEvents.length
      ? [
          {
            id: CommonTabs.All,
            label: t("common.labels.all"),
            count: compEvents.length,
          },
        ]
      : [];

    Object.values(CompEventStatus).forEach((statusOption) => {
      localTabs.push({
        id: statusOption,
        label: t(`Projects.CompEvents.status.${statusOption}`),
        count:
          compEvents.filter((compEvent) => compEvent.status === statusOption)
            .length || 0,
      });
    });

    return localTabs;
  }, [compEvents, t]);

  const handleExportToExcel = () => {
    productItemsTableRef.current?.exportToExcel();
  };

  const handleClearSelection = () => {
    productItemsTableRef.current?.clearSelection();
  };

  const handleSelectedTabChange = useCallback(
    (newSelectedTabId: string | null) => {
      if (newSelectedTabId === CommonTabs.All) {
        setLocalCompEvents(compEvents);
      } else {
        setLocalCompEvents(
          compEvents.filter(
            (compEvent) => compEvent.status === newSelectedTabId
          )
        );
      }
      setSelectedTabId(newSelectedTabId);
    },
    [compEvents]
  );

  useEffect(() => {
    handleSelectedTabChange(selectedTabId);
  }, [handleSelectedTabChange, selectedTabId]);

  return (
    <Box height="100%" width="100%" display="flex" flexDirection="column">
      <StyledProductItemsHeader
        tabs={tabs}
        selectedItemsCount={productItemsTableSelectionModel?.length ?? 0}
        showUsersAccess
        usersAccessLoading={usersAccessLoading}
        usersRoles={usersRoles}
        onSelectedTabChange={handleSelectedTabChange}
        onExportToExcel={handleExportToExcel}
        onClearSelection={handleClearSelection}
        productType={ProductType.CompEvents}
      />
      <Box sx={{ flex: 1, width: "100%" }} mt={3}>
        <CompEventsTable
          selectionModel={productItemsTableSelectionModel}
          compEvents={localCompEvents}
          loading={loading}
          apiRef={productItemsTableRef}
          onSelectionModelChange={setProductItemsTableSelectionModel}
        />
      </Box>
    </Box>
  );
};
