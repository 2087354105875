import {
  Button,
  Divider,
  IconButton,
  Stack,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import {
  DailyDiarySearchRecordsInput,
  DailyDiarySearchRecordStatus,
} from "generated/graphql";
import { ArrowLeft, ArrowRight } from "phosphor-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DiaryDateRangePicker } from "./DiaryDateRangePicker";
import { KeywordSearch } from "./KeywordSearch";
import { StatusPicker } from "./StatusPicker";
import moment from "moment";
import { dateISOFormat } from "constants/constants";
import { useDiaryStartDate } from "../hooks/useDiaryStartDate";
import { DateRange } from "@mui/x-date-pickers-pro";

type SearchFormProps = {
  collapsed?: boolean;
  productInstanceId: string;
  pageSize: number;
  onSearch: (criterias: DailyDiarySearchRecordsInput) => void;
  onToggleCollapse: () => void;
};

export const SearchForm: React.FC<SearchFormProps> = ({
  collapsed,
  productInstanceId,
  pageSize,
  onSearch,
  onToggleCollapse,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const defaultFormData = useMemo(
    () => ({
      endDate: moment().toDate(),
      startDate: moment().subtract(14, "days").toDate(),
      hasAttachments: undefined,
      keywords: "",
      page: 1,
      pageSize,
      productInstanceId: "",
      statuses: [
        DailyDiarySearchRecordStatus.Approved,
        DailyDiarySearchRecordStatus.Sent,
      ],
    }),
    [pageSize]
  );

  const [hasStatusError, setHasStatusError] = useState<boolean>(false);
  const [hasDateRangeError, setHasDateRangeError] = useState<boolean>(false);
  const { startDate: diaryStartDate, loading: prodInstanceStartDateLoading } =
    useDiaryStartDate(productInstanceId);

  const [formData, setFormData] =
    useState<DailyDiarySearchRecordsInput>(defaultFormData);

  const formHasError = useMemo(
    () => hasStatusError || hasDateRangeError,
    [hasStatusError, hasDateRangeError]
  );

  const handleDateRangeChange = (date: DateRange<Date>) => {
    setFormData((curData) => ({
      ...curData,
      startDate: date[0],
      endDate: date[1],
    }));
  };

  const handleKeywordChange = (keyword: string) => {
    setFormData((crt) => ({ ...crt, keywords: keyword }));
  };

  const handleHasAttachmentsChange = (
    _evt: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setFormData((crt) => ({
      ...crt,
      hasAttachments: checked ? true : undefined,
    }));
  };

  const handleStatusesChange = (statuses: DailyDiarySearchRecordStatus[]) => {
    setFormData((crt) => ({ ...crt, statuses }));
    setHasStatusError(!statuses.length);
  };

  const handleSearch = () => {
    if (!formHasError) {
      onSearch({
        ...formData,
        startDate: moment(formData.startDate).format(dateISOFormat),
        endDate: moment(formData.endDate).format(dateISOFormat),
        productInstanceId,
      });
    }
  };

  const resetForm = () => {
    setFormData(defaultFormData);
    setHasDateRangeError(false);
  };

  const handleErrorChange = useCallback((hasError: boolean) => {
    setHasDateRangeError(hasError);
  }, []);

  useEffect(() => {
    if (
      diaryStartDate &&
      moment(diaryStartDate).unix() > moment(formData.startDate).unix()
    ) {
      setFormData((crt) => ({ ...crt, startDate: new Date(diaryStartDate) }));
    }
  }, [diaryStartDate, formData.startDate]);

  return collapsed ? (
    <IconButton onClick={onToggleCollapse}>
      <ArrowLeft size={20} color={theme.palette.grey[800]} />
    </IconButton>
  ) : (
    <Stack direction="column" width="100%">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
        px={3}
      >
        <Typography variant="p1" color="grey.800" fontWeight={600}>
          {t("Projects.DailyDiariesSearch.search")}
        </Typography>
        <IconButton sx={{ p: 0 }} onClick={onToggleCollapse}>
          <ArrowRight size={20} color={theme.palette.grey[800]} />
        </IconButton>
      </Stack>
      <DiaryDateRangePicker
        minStartDate={diaryStartDate ? new Date(diaryStartDate) : undefined}
        disabled={prodInstanceStartDateLoading}
        hasError={hasDateRangeError}
        onErrorChange={handleErrorChange}
        value={[formData.startDate ?? null, formData.endDate ?? null]}
        onChange={handleDateRangeChange}
      />
      <Divider
        flexItem
        orientation="horizontal"
        sx={{
          my: theme.spacing(2),
          borderColor: theme.palette.grey[200],
        }}
      />
      <KeywordSearch
        onKeywordChange={handleKeywordChange}
        onEnter={handleSearch}
        keyword={formData.keywords ?? ""}
      />
      <Divider
        flexItem
        orientation="horizontal"
        sx={{
          my: theme.spacing(2),
          borderColor: theme.palette.grey[200],
        }}
      />
      <Stack
        px={3}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        height="32px"
      >
        <Typography variant="p2" fontWeight={600} color="grey.800">
          {t("Projects.DailyDiariesSearch.hasAttachments")}
        </Typography>
        <Switch
          size="small"
          checked={!!formData.hasAttachments}
          onChange={handleHasAttachmentsChange}
          data-testid={`has-attachments-switch`}
        />
      </Stack>
      <Divider
        flexItem
        orientation="horizontal"
        sx={{
          my: theme.spacing(2),
          borderColor: theme.palette.grey[200],
        }}
      />
      <StatusPicker
        value={formData.statuses}
        onChange={handleStatusesChange}
        hasError={hasStatusError}
      />
      <Divider
        flexItem
        orientation="horizontal"
        sx={{
          my: theme.spacing(2),
          borderColor: theme.palette.grey[200],
        }}
      />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        px={3}
      >
        <Button variant="text" sx={{ px: 0 }} onClick={resetForm}>
          <Typography variant="p2" fontWeight={700} color="primary.main">
            {t("Projects.DailyDiariesSearch.resetAll")}
          </Typography>
        </Button>
        <Button
          variant="contained"
          onClick={handleSearch}
          disabled={formHasError}
        >
          {t("Projects.DailyDiariesSearch.search")}
        </Button>
      </Stack>
    </Stack>
  );
};
