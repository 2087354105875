import { Box } from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { ClaimItem, ClaimStatus, ProductType } from "generated/graphql";
import { useMemo, useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TabWithCount } from "../../TabsWithCount/TabsWithCount";
import { CommonTabs } from "constants/constants";
import { StyledProductItemsHeader } from "../../ProductItemsView/ProductItemsView.styled";
import { ProductItemsTablePublicAPI } from "../../ProductItemsView/ProductItemsView.decl";
import { ClaimsTable } from "./components/ClaimsTable";
import { useSearchParams } from "react-router-dom";
import { useUsersAccess } from "../../ProductItemsView/hooks/useUsersAccess";

export type ClaimsProps = {
  claims: ClaimItem[];
  loading: boolean;
};

export const ClaimsView: React.FC<ClaimsProps> = ({ claims, loading }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const productInstanceId = searchParams.get("productInstanceId");
  const productItemsTableRef = useRef<ProductItemsTablePublicAPI>(null);
  const [productItemsTableSelectionModel, setProductItemsTableSelectionModel] =
    useState<GridRowSelectionModel>();
  const [localClaims, setLocalClaims] = useState<ClaimItem[]>(claims || []);
  const [selectedTabId, setSelectedTabId] = useState<string | null>(
    CommonTabs.All
  );
  const { usersRoles, usersAccessLoading } = useUsersAccess(productInstanceId!);

  const tabs = useMemo(() => {
    const localTabs: TabWithCount[] = claims.length
      ? [
          {
            id: CommonTabs.All,
            label: t("common.labels.all"),
            count: claims.length,
          },
        ]
      : [];

    Object.values(ClaimStatus).forEach((statusOption) => {
      if (
        (statusOption as any) === ClaimStatus.Accepted ||
        (statusOption as any) === ClaimStatus.Rejected
      )
        return; // TODO: to be removed after ClaimStatus have these 2 statuses removed

      localTabs.push({
        id: statusOption,
        label: t(`Projects.Claims.status.${statusOption}`),
        count:
          claims.filter((claim) => claim.status === statusOption).length || 0,
      });
    });

    return localTabs;
  }, [claims, t]);

  const handleExportToExcel = () => {
    productItemsTableRef.current?.exportToExcel();
  };

  const handleClearSelection = () => {
    productItemsTableRef.current?.clearSelection();
  };

  const handleSelectedTabChange = useCallback(
    (newSelectedTabId: string | null) => {
      if (newSelectedTabId === CommonTabs.All) {
        setLocalClaims(claims);
      } else {
        setLocalClaims(
          claims.filter((claim) => claim.status === newSelectedTabId)
        );
      }
      setSelectedTabId(newSelectedTabId);
    },
    [claims]
  );

  useEffect(() => {
    handleSelectedTabChange(selectedTabId);
  }, [handleSelectedTabChange, selectedTabId]);

  return (
    <Box height="100%" width="100%" display="flex" flexDirection="column">
      <StyledProductItemsHeader
        tabs={tabs}
        selectedItemsCount={productItemsTableSelectionModel?.length ?? 0}
        showUsersAccess
        usersAccessLoading={usersAccessLoading}
        usersRoles={usersRoles}
        onSelectedTabChange={handleSelectedTabChange}
        onExportToExcel={handleExportToExcel}
        onClearSelection={handleClearSelection}
        productType={ProductType.Claims}
      />
      <Box sx={{ flex: 1, width: "100%" }} mt={3}>
        <ClaimsTable
          selectionModel={productItemsTableSelectionModel}
          claims={localClaims}
          loading={loading}
          apiRef={productItemsTableRef}
          onSelectionModelChange={setProductItemsTableSelectionModel}
        />
      </Box>
    </Box>
  );
};
