import {
  Box,
  Breadcrumbs,
  Collapse,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { DetailsList } from "components/DetailsList";
import { EntityDetailsHeader } from "components/EntityDetailsHeader";
import { ContractsIcon } from "components/Icons/ContractsIcon";
import { PageContentContainer } from "components/PageContentContainer";
import { useContractStatusOptions } from "components/StatusTag/useContractStatusOptions";
import { FormPublicApi, OnMutationDone } from "types/decl";
import {
  AddContractInput,
  AddDocumentTemplateRouteInput,
  AddDocumentTemplateRouteOverrideInput,
  ChangeContractBindingStatusMutation,
  ChangeContractBindingStatusMutationVariables,
  ChangeContractStatusMutation,
  ChangeContractStatusMutationVariables,
  Contract,
  ContractBinding,
  ContractBindingStatus,
  ContractKeyDate,
  ContractQuery,
  ContractQueryVariables,
  ContractSection,
  ContractStatus,
  ContractType,
  ContractTypesQuery,
  ContractTypesQueryVariables,
  DocumentTemplateRoute,
  EditContractInput,
  EditContractMutation,
  EditContractMutationVariables,
  EditDocumentTemplateRouteInput,
  EditDocumentTemplateRouteOverrideInput,
  Product,
  ProductInstance,
  ProductsWithOutputActionsQuery,
  ProductsWithOutputActionsQueryVariables,
  User,
} from "generated/graphql";
import moment from "moment";
import { changeContractStatusMutation } from "graphql/mutations/changeContractStatus";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ContractForm } from "../components/ContractForm/ContractForm";
import { getContractTypeById } from "../components/ContractForm/ContractForm.utils";
import { ContractBindings } from "./ContractBindings/ContractBindings";
import { contractQuery, editContractMutation } from "./ContractDetails.query";
import { contractTypesQuery } from "graphql/queries/contractTypes.query";
import { changeContractBindingStatusMutation } from "graphql/mutations/changeContractBindingStatus";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { getTimezoneOffset } from "helpers/timezones";
import { StatusOption } from "components/StatusTag/StatusTag";
import { formatNumber, getUserName } from "helpers/miscelaneous";
import { ContractProductInstances } from "./ContractProductInstances/ContractProductInstances";
import { useContractProductInstances } from "./ContractProductInstances/useContractProductInstances";
import { DocumentTemplateRoutes } from "../../System/containers/Templates/DocumentTemplateRouter/DocumentTemplateRoutes/DocumentTemplateRoutes";
import { productsWithOutputActionsQuery } from "graphql/queries/products.query";
import { useDocumentTemplateOverrides } from "../../System/containers/Templates/DocumentTemplateRouter/DocumentTemplateRoutes/useDocumentTemplateOverrides";
import { ContractSections } from "./ContractSections/ContractSections";
import { useContractSections } from "./ContractSections/useContractSections";
import { ContractKeyDates } from "./ContractKeyDates/ContractKeyDates";
import { useContractKeyDates } from "./ContractKeyDates/useContractKeyDates";
import { TimePeriodsOverride } from "./TimePeriodsOverride/TimePeriodsOverride";
import { useTimePeriodsOverride } from "./TimePeriodsOverride/useTimePeriodsOverride";
import { ClaimTypesOverride } from "./ClaimTypes/ClaimTypes";
import { useClaimTypes } from "./ClaimTypes/useClaimTypes";
import { dateISOFormat } from "constants/constants";
import { useProjectLite } from "containers/AdminConsole/hooks/useProjectLite";
import { isNECContractType } from "containers/Projects/Projects.utils";
import { useDocumentTemplates } from "../../System/containers/Templates/useDocumentTemplates";
import { ContractUsers } from "./ContractUsers/ContractUsers";
import {
  contractKeyDateToEditContractKeyDateInput,
  contractSectionToEditContractSectionInput,
} from "./ContractDetails.utils";

export const ContractDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { projectId, contractId } = useParams();
  const contractFormRef = useRef<FormPublicApi>(null);
  const statusOptions =
    useContractStatusOptions() as StatusOption<ContractStatus>[];

  const updatedContractDetails = useRef<EditContractInput>();
  const [showDetails, setShowDetails] = useState(true);
  const [readOnly, setReadOnly] = useState(true);

  const {
    data: contractData,
    refetch: refetchContractData,
    loading: getContractDataLoading,
  } = useGraphQuery<ContractQuery, ContractQueryVariables>(contractQuery, {
    variables: { id: contractId! },
  });

  const showContractKeyDates = contractData
    ? !!isNECContractType(contractData.contract as Contract)
    : false;

  const { projectDataLite, loading: projectDataLoading } = useProjectLite(
    projectId!
  );

  const { data: contractTypes, loading: getContractTypesLoading } =
    useGraphQuery<ContractTypesQuery, ContractTypesQueryVariables>(
      contractTypesQuery
    );

  const { documentTemplates, fetchDocumentTemplatesLoading } =
    useDocumentTemplates(true, true);

  const {
    data: productsWithOutputActions,
    loading: fetchProductsWithOutputActionsLoading,
  } = useGraphQuery<
    ProductsWithOutputActionsQuery,
    ProductsWithOutputActionsQueryVariables
  >(productsWithOutputActionsQuery);

  const [changeContractStatus, { loading: changeContractStatusLoading }] =
    useGraphMutation<
      ChangeContractStatusMutation,
      ChangeContractStatusMutationVariables
    >(
      changeContractStatusMutation,
      {
        update: (cache) => {
          refetchContractData();

          cache.evict({ id: "ROOT_QUERY", fieldName: "project" });
          cache.evict({ id: "ROOT_QUERY", fieldName: "projects" });
          cache.gc();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("common.labels.project"),
      })
    );

  const [
    changeContractBindingStatus,
    { loading: changeContractBindingStatusLoading },
  ] = useGraphMutation<
    ChangeContractBindingStatusMutation,
    ChangeContractBindingStatusMutationVariables
  >(
    changeContractBindingStatusMutation,
    {
      update: (cache) => {
        refetchContractData();
        cache.evict({ id: "ROOT_QUERY", fieldName: "productInstance" });
        cache.gc();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("common.labels.binding"),
    })
  );

  const [editContract, { loading: editContractLoading }] = useGraphMutation<
    EditContractMutation,
    EditContractMutationVariables
  >(
    editContractMutation,
    {
      update: (cache) => {
        refetchContractData();

        cache.evict({ id: "ROOT_QUERY", fieldName: "project" });
        cache.gc();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("common.labels.contract"),
    })
  );

  const handleProductInstanceStatusChange = useCallback(() => {
    refetchContractData();
  }, [refetchContractData]);

  const {
    changeStatus: changeProductInstanceStatus,
    changeStatusLoading: changeProductInstanceStatusLoading,
  } = useContractProductInstances({
    onStatusChange: handleProductInstanceStatusChange,
  });

  const handleContractSectionsKeyDatesEdited = useCallback(() => {
    refetchContractData();
  }, [refetchContractData]);

  const {
    loading: contractSectionsLoading,
    addSection,
    editSection,
  } = useContractSections({
    onEdit: handleContractSectionsKeyDatesEdited,
    contract: contractData?.contract as Contract,
  });

  const {
    loading: contractKeyDatesLoading,
    addKeyDate,
    editKeyDate,
  } = useContractKeyDates({
    onEdit: handleContractSectionsKeyDatesEdited,
    contract: contractData?.contract as Contract,
  });

  const {
    timePeriods,
    timePeriodsOverride,
    loading: timePeriodsLoading,
    addTimePeriodOverride,
    removeTimePeriodOverride,
  } = useTimePeriodsOverride(
    contractData?.contract.contractType.id,
    contractData?.contract.id
  );

  const {
    contractTypeClaimTypes,
    additionalClaimTypes,
    claimTypesLoading,
    additionalClaimTypesLoading,
    addClaimType,
    changeClaimTypeStatus,
    changeNewClaimTypeStatus,
  } = useClaimTypes({
    contractId: contractId!,
    contractTypeId: contractData?.contract.contractTypeId,
  });

  const handleStatusChange = useCallback(
    async (newStatus: ContractStatus) => {
      await changeContractStatus({
        variables: {
          id: contractData!.contract.id,
          status: newStatus,
        },
      });
    },
    [changeContractStatus, contractData]
  );

  const handleBindingStatusChange = useCallback(
    async (row: ContractBinding, newStatus: ContractBindingStatus) => {
      await changeContractBindingStatus({
        variables: {
          id: row.id,
          status: newStatus,
        },
      });
    },
    [changeContractBindingStatus]
  );

  const handleEditClick = useCallback(() => {
    setReadOnly(false);
    setShowDetails(true);
  }, []);

  const handleCancelClick = useCallback(() => {
    contractFormRef.current?.reset();
    setReadOnly(true);
  }, []);

  const handleSaveClick = useCallback(async () => {
    if (
      !updatedContractDetails.current ||
      !contractFormRef.current?.validate()
    ) {
      return;
    }

    const { errors } = await editContract({
      variables: {
        input: updatedContractDetails.current,
      },
    });

    if (!errors) {
      setReadOnly(true);
    }
  }, [editContract]);

  const handleContractChange = useCallback(
    (updatedContract: AddContractInput | EditContractInput) => {
      updatedContractDetails.current = {
        ...(updatedContract as EditContractInput),
        sections: (contractData?.contract.sections ?? []).map((section) =>
          contractSectionToEditContractSectionInput(section as ContractSection)
        ),
        keyDates: (contractData?.contract.keyDates ?? []).map((keyDate) =>
          contractKeyDateToEditContractKeyDateInput(keyDate as ContractKeyDate)
        ),
      };
    },
    [contractData]
  );

  const handleDocumentTemplateRoutesUpdated: OnMutationDone = (cache) => {
    refetchContractData();
    cache.evict({ id: "ROOT_QUERY", fieldName: "documentTemplateRoutes" });
    cache.gc();
  };

  const {
    addDocumentTemplateRouteOverride,
    addDocumentTemplateRouteOverrideLoading,
    editDocumentTemplateRouteOverride,
    editDocumentTemplateRouteOverrideLoading,
    removeDocumentTemplateRouteOverride,
    removeDocumentTemplateRouteOverrideLoading,
  } = useDocumentTemplateOverrides({
    onAdd: handleDocumentTemplateRoutesUpdated,
    onEdit: handleDocumentTemplateRoutesUpdated,
    onRemove: handleDocumentTemplateRoutesUpdated,
  });

  const handleAddDocumentTemplateRouteOverride = (
    input: AddDocumentTemplateRouteInput | AddDocumentTemplateRouteOverrideInput
  ) => {
    return addDocumentTemplateRouteOverride(
      input as AddDocumentTemplateRouteOverrideInput
    );
  };
  const handleEditDocumentTemplateRouteOverride = (
    input:
      | EditDocumentTemplateRouteInput
      | EditDocumentTemplateRouteOverrideInput
  ) => {
    return editDocumentTemplateRouteOverride(
      input as EditDocumentTemplateRouteOverrideInput
    );
  };

  const handleBreadcrumbClick = (parent?: "project") => {
    if (parent === "project") {
      navigate(-1);
    } else {
      navigate(-2);
    }
  };

  const creatorStr = useMemo(() => {
    if (
      contractData?.contract.creator.firstname &&
      contractData.contract.creator.surname
    ) {
      return getUserName(contractData?.contract.creator as User | undefined);
    }

    return "";
  }, [contractData]);

  const loading = useMemo(() => {
    return (
      getContractDataLoading ||
      changeContractStatusLoading ||
      editContractLoading ||
      projectDataLoading ||
      getContractTypesLoading
    );
  }, [
    getContractDataLoading,
    changeContractStatusLoading,
    editContractLoading,
    projectDataLoading,
    getContractTypesLoading,
  ]);

  const contractEntityObj = useMemo(() => {
    if (
      !contractData?.contract ||
      !contractTypes?.contractTypes.items ||
      !contractTypes.contractTypes.items.length
    ) {
      return [];
    }

    const selectedContractType = getContractTypeById(
      contractData.contract.contractTypeId,
      contractTypes.contractTypes.items as ContractType[]
    )!;
    const typeName = `${selectedContractType.description} ${
      selectedContractType.version ?? ""
    } ${selectedContractType.subType ?? ""}`;

    return [
      {
        id: "name",
        label: t("common.labels.name"),
        value: contractData.contract.name,
      },
      {
        id: "friendlyName",
        label: t("common.labels.friendlyName"),
        value: contractData.contract.friendlyName,
      },
      {
        id: "number",
        label: t("common.labels.number"),
        value: contractData.contract.number,
      },
      {
        id: "contractTypeId",
        label: t("common.labels.type"),
        value: typeName,
      },
      {
        id: "currency",
        label: t("common.labels.currency"),
        value: contractData.contract.valueCurrency,
      },
      {
        id: "value",
        label: t("common.labels.value"),
        value: contractData.contract.value
          ? formatNumber(contractData.contract.value)
          : "",
      },
      {
        id: "startDate",
        label: t("common.labels.startDate"),
        value: contractData.contract.startDate
          ? moment(new Date(contractData.contract.startDate)).format(
              dateISOFormat
            )
          : "",
      },
      {
        id: "endDate",
        label: t("AdminConsole.Contracts.labels.endDate"),
        value: contractData.contract.endDate
          ? moment(new Date(contractData.contract.endDate)).format(
              dateISOFormat
            )
          : "",
      },
      {
        id: "province",
        label: t("AdminConsole.Contracts.labels.province"),
        value: contractData.contract.province,
      },
      {
        id: "country",
        label: t("common.labels.country"),
        value: contractData.contract.country,
      },
      {
        id: "timezone",
        label: t("common.labels.timezone"),
        value: `${contractData.contract.timeZone} (${getTimezoneOffset(
          contractData.contract.timeZone
        )})`,
      },
      {
        id: "coordinatesLatitude",
        label: t("common.labels.siteLatitude"),
        value: contractData.contract.coordinatesLatitude,
      },
      {
        id: "coordinatesLongitude",
        label: t("common.labels.siteLongitude"),
        value: contractData.contract.coordinatesLongitude,
      },
    ];
  }, [contractData, contractTypes, t]);

  return (
    <Box>
      <EntityDetailsHeader
        loading={loading}
        title={contractData?.contract.friendlyName || ""}
        subtitle={
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link key="1" onClick={() => handleBreadcrumbClick()}>
              <Typography variant="body2">
                {t("AdminConsole.Projects.labels.projects")}
              </Typography>
            </Link>
            <Link key="2" onClick={() => handleBreadcrumbClick("project")}>
              <Typography variant="body2">
                {projectDataLite?.friendlyName}
              </Typography>
            </Link>
          </Breadcrumbs>
        }
        status={contractData?.contract.status}
        statusOptions={statusOptions}
        creator={creatorStr}
        dateCreated={contractData?.contract.dateCreated}
        onStatusChange={handleStatusChange}
      />
      <CollapsibleHeader
        title={t("AdminConsole.Contracts.labels.contractDetails")}
        collapsed={!showDetails}
        icon={<ContractsIcon />}
        onToggleCollapse={() => setShowDetails((state) => !state)}
        withShadow={false}
        editable
        editMode={!readOnly}
        onCancel={handleCancelClick}
        onEdit={handleEditClick}
        onSave={handleSaveClick}
        onSaveActionLoading={editContractLoading}
      />
      <Collapse in={showDetails}>
        <PageContentContainer>
          {readOnly ? (
            <DetailsList loading={loading} entity={contractEntityObj} />
          ) : loading ? null : (
            <ContractForm
              contractTypes={
                contractTypes!.contractTypes.items as ContractType[]
              }
              contract={contractData!.contract as Contract}
              disabled={loading}
              apiRef={contractFormRef}
              projectId={projectDataLite!.id!}
              onChange={handleContractChange}
            />
          )}
        </PageContentContainer>
      </Collapse>
      <Stack spacing={2}>
        <ContractBindings
          bindings={
            (contractData?.contract.contractBindings
              .items as ContractBinding[]) || []
          }
          loading={getContractDataLoading || changeContractBindingStatusLoading}
          onBindingStatusChange={handleBindingStatusChange}
          projectFriendlyName={projectDataLite?.friendlyName}
          contractFriendlyName={contractData?.contract.friendlyName}
        />
        <ContractUsers
          projectFriendlyName={projectDataLite?.friendlyName}
          contractFriendlyName={contractData?.contract.friendlyName}
        />
        <ContractProductInstances
          productInstances={
            (contractData?.contract.productInstances
              .items as ProductInstance[]) || []
          }
          loading={getContractDataLoading || changeProductInstanceStatusLoading}
          projectFriendlyName={projectDataLite?.friendlyName}
          contractFriendlyName={contractData?.contract.friendlyName}
          onProductInstanceStatusChange={changeProductInstanceStatus}
        />
        <DocumentTemplateRoutes
          collapsibleHeader
          products={
            (productsWithOutputActions?.products.items ?? []) as Product[]
          }
          fixedContractType={
            contractData?.contract.contractType as ContractType
          }
          contractId={contractData?.contract.id}
          contractName={contractData?.contract.name}
          contractTypes={[]}
          documentTemplates={documentTemplates}
          documentTemplateRoutes={
            (contractData?.contract.templateRoutesOverride.items ??
              []) as DocumentTemplateRoute[]
          }
          loading={
            fetchDocumentTemplatesLoading ||
            fetchProductsWithOutputActionsLoading ||
            getContractDataLoading ||
            addDocumentTemplateRouteOverrideLoading ||
            editDocumentTemplateRouteOverrideLoading ||
            removeDocumentTemplateRouteOverrideLoading
          }
          onAddDocumentTemplateRoute={handleAddDocumentTemplateRouteOverride}
          onEditDocumentTemplateRoute={handleEditDocumentTemplateRouteOverride}
          onRemoveDocumentTemplateRoute={removeDocumentTemplateRouteOverride}
        />
        <ContractSections
          sections={
            (contractData?.contract.sections ?? []) as ContractSection[]
          }
          loading={contractSectionsLoading}
          projectFriendlyName={projectDataLite?.friendlyName}
          contractFriendlyName={contractData?.contract.friendlyName}
          onSectionAdd={addSection}
          onSectionEdit={editSection}
        />
        {showContractKeyDates && (
          <ContractKeyDates
            keyDates={
              (contractData?.contract.keyDates ?? []) as ContractKeyDate[]
            }
            loading={contractKeyDatesLoading}
            projectFriendlyName={projectDataLite?.friendlyName}
            contractFriendlyName={contractData?.contract.friendlyName}
            onKeyDateAdd={addKeyDate}
            onKeyDateEdit={editKeyDate}
          />
        )}
        <TimePeriodsOverride
          contract={contractData?.contract as Contract}
          timePeriods={timePeriods}
          projectFriendlyName={projectDataLite?.friendlyName}
          timePeriodsOverride={timePeriodsOverride}
          loading={timePeriodsLoading}
          onTimePeriodOverrideAdd={addTimePeriodOverride}
          onTimePeriodOverrideRemove={removeTimePeriodOverride}
        />
        <ClaimTypesOverride
          contractTypeClaimTypes={contractTypeClaimTypes}
          newClaimTypes={additionalClaimTypes}
          contractId={contractId!}
          claimTypesLoading={claimTypesLoading}
          additionalClaimTypesLoading={additionalClaimTypesLoading}
          projectFriendlyName={projectDataLite?.friendlyName}
          contractFriendlyName={contractData?.contract.friendlyName}
          onAddNewClaimType={addClaimType}
          onContractTypeClaimTypeStatusChange={changeClaimTypeStatus}
          onNewClaimTypeStatusChange={changeNewClaimTypeStatus}
        />
      </Stack>
    </Box>
  );
};
