import { gql } from "@apollo/client";

export const globalConfigurationQuery = gql`
  query globalConfiguration {
    globalConfiguration {
      maxUploadFileSize
      dailyDiarySearchPageSize
    }
  }
`;
